import { Prop, Watch, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import smoothscrollbar from "@/vue/directives/smoothscrollbar";

@Component({
  directives: {
    smoothscrollbar,
  },
})
export default class DfTabbedPanelComponent extends Vue {
  @Prop({ type: String, default: "df-panel" })
  id!: string;

  @Prop({ type: String, default: "overlay" })
  mode!: string;

  @Prop({ type: String, default: "right" })
  position!: string;

  @Prop({ type: String, default: "" })
  color!: string;

  @Prop({ type: String, default: "100%" })
  width!: string;

  @Prop({ type: Boolean, default: false })
  collapsable!: boolean;

  @Prop({ type: Boolean, default: true })
  withHeader!: boolean;

  @Prop({ type: Boolean, default: true })
  withFooter!: boolean;

  @Prop({ type: Boolean, default: false })
  isOpen!: boolean;

  @Prop({ type: Boolean, default: true })
  isDestructible!: boolean;

  @Prop({ type: Boolean, default: false })
  startCollapsed!: boolean;

  @Prop({ type: Boolean, default: false })
  transparent!: boolean;

  @Prop({ type: Array })
  menu!: Array<any>;

  private closed = true;
  private menuItems: Array<any> = [];

  beforeMount() {
    this.menu.forEach((item: any) => {
      this.menuItems.push({
        id: item.id,
        name: this.$t(item.name),
        active: this.menu.indexOf(item) === 0,
        customClassList: "",
      });
    });
  }

  mounted() {
    if (!this.isDestructible || this.isOpen) {
      this.setPageScrolling(false);
      this.setPanelWidth();
      this.setClosed(false);
    } else {
      this.close();
    }
    this.setPageScrolling(true);
    this.setViewportFluid(true);
    if (this.isDestructible) {
      this.setClosed(true);
    }
    this.$root.$on("open-panel", (id: string) => {
      if (id === this.id) {
        this.open();
      }
    });
    this.$root.$on("close-panel", (id: string) => {
      if (id === this.id) {
        this.close();
      }
    });
    this.$root.$on("close-all-panel", () => {
      this.close();
    });

    this.$root.$on(
      "ui-tabbed-panel.set-tab-custom-class-list",
      (tabId: string, customClassList: Array<string>) => {
        const tab: any = this.menuItems.find((tab: any) => tab.id === tabId);
        if (tab !== null) {
          tab.customClassList = customClassList.join(" ");
        }
      }
    );
  }

  beforeDestroy() {
    this.close();
  }

  @Watch("isOpen")
  onIsOpenChange() {
    if (this.isOpen) {
      if (this.startCollapsed) {
        this.$emit("close");
        this.setPageScrolling(true);
        this.setViewportFluid(true);
        if (this.isDestructible) {
          this.setClosed(true);
        }
      } else {
        this.open();
      }
    } else {
      if (this.collapsable) {
        this.$emit("close");
        this.setPageScrolling(true);
        this.setViewportFluid(true);
        if (this.isDestructible) {
          this.setClosed(true);
        }
      } else {
        this.close();
      }
    }
  }

  get isOverlay() {
    return this.mode === "overlay";
  }

  get isFixed() {
    return this.mode === "fixed";
  }

  get isClosed() {
    return this.closed;
  }

  get activeTab() {
    return this.menuItems.find((item: any) => {
      return item.active;
    });
  }

  close() {
    this.$emit("close");
    this.setPageScrolling(true);
    this.setViewportFluid(true);
    this.setPanelWidth(0);
    if (this.isDestructible) {
      this.setClosed(true);
    }
  }

  open() {
    this.setPageScrolling(false);
    this.setViewportFluid(false);
    this.setPanelWidth();
    this.setClosed(false);
  }

  togglePanel() {
    if (this.isClosed) {
      this.$emit("open");
      this.setPageScrolling(false);
      this.setViewportFluid(false);
      this.setClosed(false);
    } else {
      this.$emit("close");
      this.setPageScrolling(true);
      this.setViewportFluid(true);
      if (this.isDestructible) {
        this.setClosed(true);
      }
    }
  }

  private setPageScrolling(scrollEnabled: boolean) {
    const body = document.querySelector("body");
    if (body != null && this.isOverlay) {
      scrollEnabled
        ? body.classList.remove("stop-scrolling")
        : body.classList.add("stop-scrolling");
    }
  }

  private setViewportFluid(fluid: boolean) {
    const body = document.querySelector("body");
    const uiStickyElements: NodeListOf<HTMLElement> =
      document.querySelectorAll(".ui-sticky");
    if (body != null && this.isFixed) {
      switch (this.position) {
        case "right":
          if (fluid) {
            body.style.marginRight = "0";
            uiStickyElements.forEach((uiStickyElements: HTMLElement) => {
              uiStickyElements.style.right = "0";
            });
          } else {
            body.style.marginRight = this.width;
            uiStickyElements.forEach((uiStickyElements: HTMLElement) => {
              uiStickyElements.style.right = this.width;
            });
          }
          break;
        case "left":
          if (fluid) {
            body.style.marginLeft = "0";
            uiStickyElements.forEach((uiStickyElements: HTMLElement) => {
              uiStickyElements.style.left = "0";
            });
          } else {
            body.style.marginLeft = this.width;
            uiStickyElements.forEach((uiStickyElements: HTMLElement) => {
              uiStickyElements.style.left = this.width;
            });
          }
          break;
        default:
          break;
      }
    }
  }

  private setPanelWidth(width?: number) {
    const uiPanel = <HTMLDivElement>this.$el.querySelector(".ui-panel");
    if (uiPanel != null) {
      uiPanel.style.width = width != null ? width.toString() : this.width;
    }
  }

  private setClosed(closed: boolean) {
    this.closed = closed;
  }

  private nextTab(): void {
    this.setActiveTab(
      this.menuItems.indexOf(this.activeTab) === this.menuItems.length - 1
        ? this.menuItems[0]
        : this.menuItems[this.menuItems.indexOf(this.activeTab) + 1]
    );
  }

  private previousTab(): void {
    this.setActiveTab(
      this.menuItems.indexOf(this.activeTab) === 0
        ? this.menuItems[this.menuItems.length - 1]
        : this.menuItems[this.menuItems.indexOf(this.activeTab) - 1]
    );
  }

  private setActiveTab(activeTab: any): void {
    this.menuItems.forEach((item: any) => {
      item.active = item.id === activeTab.id;
    });
  }
}
