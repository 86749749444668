import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfProduct from "@/vue/domain/product/df-product";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfStore from "@/vue/domain/store/df-store";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import moment from "moment";

@Component({
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
    shortDateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_SHORT_PATTER) : "";
    },
    percentual: (value: string) => {
      const data: string = new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 0,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(0)));
      return `-${data}%`;
    },
    decimal: (value: string) => {
      return new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 2,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(2)));
    },
    number: (value: number) => {
      return new Intl.NumberFormat(navigator.language).format(value);
    },
    price: (value: string, unit: string) => {
      unit = unit ? `${unit}` : "";
      const result: string = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat(value) || 0);
      if (unit == "") {
        return `${result}`;
      } else if (unit == "KG" || unit == "L") {
        return `${unit} ${result}`;
      } else {
        return `${unit}`;
      }
    },
    unit: (value: string, simple: boolean) => {
      const prefix: string = simple ? "" : "al ";
      return value != null ? prefix + value.toLowerCase() : "";
    },
  },
})
export default class DfCardProductComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: null })
  product: DfProduct;
  @Prop({ type: String, default: null })
  promotionAlias: string;
  @Prop({ type: Boolean, default: false })
  brandMissingPreview: boolean;
  @Prop({ type: Boolean, default: false })
  showId: boolean;
  @Prop({ type: String, default: "P" })
  hashIdPrefix: string;
  @Prop({ type: String, default: "smt-digitalflyer-ui" })
  origin: string;

  get currentPromotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  get differentDiscountDate(): boolean {
    const rule01: boolean = !!this.productDateFrom && !!this.productDateTo;
    const rule02: boolean = this.currentPromotion.endDate !== this.productDateTo || this.currentPromotion.startDate !== this.productDateFrom;

    return rule01 && rule02;
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get isSelected(): boolean {
    return this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === this.title) > -1;
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  set shoppingList(shoppingList: Array<DfShoppingProduct>) {
    this.$store.dispatch("setShoppingList", shoppingList);
  }

  get discount(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DISCOUNT, "STRING");
  }

  get pieces(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_PIECES, "STRING");
  }

  get discountPercentual(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DISCOUNT_PERCENTUAL, "NUMBER");
  }

  get initialPrice(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_INITIAL_PRICE, "NUMBER");
  }

  get finalPrice(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FINAL_PRICE, "NUMBER");
  }

  get finalPriceForUnit(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FINAL_PRICE_FOR_UNIT, "NUMBER");
  }

  get captionValue(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_CAPTION, "STRING");
  }

  get unitMisure(): string {
    const value = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_UNIT, "STRING");
    return value == "HG" ? this.$t("df-measure-unit.hg.label").toString() : value;
  }

  get points(): number {
    const points: number = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_POINTS, "NUMBER");
    return points ? this.$options.filters!.number(points) : null;
  }

  get contributionPoints(): number {
    const contributionPoints: number = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_CONTRIBUTION_POINTS, "NUMBER");
    return contributionPoints ? this.$options.filters!.number(contributionPoints) : null;
  }

  get contributionPrice(): number {
    const contributionPrice: number = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_CONTRIBUTION_PRICE, "NUMBER");

    return contributionPrice ? this.$options.filters!.price(contributionPrice) : null;
  }

  get brandLogoImageUrl(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO, `${Utils.getPublicPath()}/assets/logo.png`);
  }

  get previewUrl(): string {
    return Utils.getPropertyImageUrl(this.product, Utils.PROPERTY_PRODUCT_PREVIEW, null);
  }

  get brandLogosUrl(): Array<string> {
    return Utils.getPropertyImagesUrl(this.product, Utils.PROPERTY_PRODUCT_BRAND_LOGOS);
  }

  get propertyLogosUrl(): Array<string> {
    return Utils.getPropertyImagesUrl(this.product, Utils.PROPERTY_PRODUCT_PROPERTY_LOGOS);
  }

  get propertyMarks(): Array<string> {
    return Utils.getPropertyValues(this.product, Utils.PROPERTY_PRODUCT_PROPERTY_MARK, "STRING");
  }

  get missingPreviewUrl(): string {
    return this.brandMissingPreview ? this.brandLogoImageUrl : this.previewUrl;
  }

  get productDateFrom(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DATE_FROM, "DATE");
  }

  get productDateTo(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DATE_TO, "DATE");
  }

  get startDate(): string {
    const startDate: string = this.productDateFrom ? this.productDateFrom : this.currentPromotion.startDate;
    return startDate ? this.$options.filters!.shortDateFormat(startDate) : null;
  }

  get endDate(): string {
    const endDate: string = this.productDateTo ? this.productDateTo : this.currentPromotion.endDate;
    return endDate ? this.$options.filters!.shortDateFormat(endDate) : null;
  }

  get urlEcommerce(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_URL_ECOMMERCE, "STRING");
  }

  get urlVideo(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_URL_VIDEO, "STRING");
  }

  get url(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_URL, "STRING");
  }

  get title(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_TITLE, "STRING");
  }

  get discountValue(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DISCOUNT_VALUE, "NUMBER");
  }

  get urlVideoIcon(): string {
    return `${Utils.getPublicPath()}/assets/product-url-video-icon.png`;
  }

  get urlIcon(): string {
    return `${Utils.getPublicPath()}/assets/product-url-icon.png`;
  }

  get urlStoreLocator(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_BRAND_URL_STORE_LOCATOR, "STRING" );
  }

  get buttonEcommerceUrl(): string {
    return this.urlEcommerce ? this.urlEcommerce : this.urlStoreLocator;
  }

  get buttonEcommerceIcon(): string {
    return this.urlEcommerce ? `${Utils.getPublicPath()}/assets/book-carry.svg` : `${Utils.getPublicPath()}/assets/store-locator.svg`;
  }

  get buttonEcommerceLabel(): string {
    return this.urlEcommerce ? this.$t("df-card-product.reserve").toString() : this.$t("df-card-product.buy-in-shop").toString();
  }

  get productDescription(): string {
    const forceMaiuscole: boolean = Utils.getPropertyValue(this.brand, Utils.PROPERTY_BRAND_FORCE_UPPERCASE_PRODUCT_DESCRIPTION, "BOOLEAN", false);
    const description: string = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DESCRIPTION, "STRING");
    const longDescription: string = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_LONG_DESCRIPTION, "TEXT");

    const productDescription: string = description || longDescription || this.product.description || "";
    return forceMaiuscole ? productDescription.toUpperCase() : productDescription;
  }

  private toggleSelection() {
    const index: number = this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === this.title);
    if (index === -1) {
      this.shoppingList = [new DfShoppingProduct(this.title, false), ...this.shoppingList];
      Utils.setAnaliticsTraker({
        event: "add_product_to_shopping_list",
        origin: this.origin,
        productAlias: this.product.alias,
        productBrand: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_BRAND, "STRING"),
        productCategory: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_CATEGORY, "STRING"),
        productCode: this.product.code,
        productDescription: this.product.description,
        productDiscountType: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DISCOUNT, "STRING"),
        productFeatured: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FEATURED, "STRING"),
        productInitialPrice: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_INITIAL_PRICE, "STRING"),
        productPrice: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FINAL_PRICE, "STRING"),
        productTitle: Utils.getPropertyValue(this.product, Utils.PROPERTY_TITLE, "STRING"),
        promotionAlias: this.currentPromotion.alias,
        promotionBeginDate: this.currentPromotion.startDate,
        promotionCode: this.currentPromotion.code,
        promotionDescription: this.currentPromotion.description,
        promotionEndDate: this.currentPromotion.endDate,
        storeAlias: this.currentStore.alias,
        storeCode: this.currentStore.code,
        storeName: this.currentStore.name,
      });
    } else {
      this.shoppingList = this.shoppingList.slice(0, index).concat(this.shoppingList.slice(index + 1, this.shoppingList.length));
    }
  }

  private setAnaliticsTraker() {
    Utils.setAnaliticsTraker({
      event: "click_product",
      origin: this.origin,
      productAlias: this.product.alias,
      productBrand: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_BRAND, "STRING"),
      productCategory: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_CATEGORY, "STRING"),
      productCode: this.product.code,
      productDescription: this.product.description,
      productDiscountType: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DISCOUNT, "STRING"),
      productFeatured: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FEATURED, "STRING"),
      productInitialPrice: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_INITIAL_PRICE, "STRING"),
      productPrice: Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FINAL_PRICE, "STRING"),
      productTitle: Utils.getPropertyValue(this.product, Utils.PROPERTY_TITLE, "STRING"),
      promotionAlias: this.currentPromotion.alias,
      promotionBeginDate: this.currentPromotion.startDate,
      promotionCode: this.currentPromotion.code,
      promotionDescription: this.currentPromotion.description,
      promotionEndDate: this.currentPromotion.endDate,
      storeAlias: this.currentStore.alias,
      storeCode: this.currentStore.code,
      storeName: this.currentStore.name,
    });
  }

  private openNewPage() {
    window.open(this.buttonEcommerceUrl, "_blank");
  }
}
