import { DirectiveOptions } from "vue";
import Scrollbar from "smooth-scrollbar";

const smoothscrollbar: DirectiveOptions = {
  bind(el, binding, vnode) {
    const vm: any = vnode.context;
    const scrollbar = Scrollbar.init(el, binding.value);

    /**
     * Enable lazy loading on scroll
     */
    if (vm.loadNextProductsPage) {
      scrollbar.addListener((status) => {
        if (status.limit.y == status.offset.y) {
          vm.loadNextProductsPage();
        }
      });
    }
  },
};

export default smoothscrollbar;
